/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

@import "bootstrap-icons";

.cursor-pointer {
  cursor: pointer !important;
}

button {
  outline: none;
}

input::placeholder{
  font-size: 12px;
}

.toast-container {
  z-index: 1200;
  width: 100%;
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
}

.loader {
  display: flex;
  justify-content: center;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -ms-transform: rotate(0deg); -o-transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dropdown-item.active, .dropdown-item:active {
  color: black !important;
  text-decoration: none;
  background-color:transparent !important;
}
.ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
  width: 4rem !important;
}
.pac-container{z-index: 9999999999999999 !important;}